import {FilDiscussion, FILDISCUSSION_CONSTANT, filDiscussionDataImpl} from "../../../../constants/type/FilDiscussion";
import _ from "lodash";
import {request, uploadFileRequest} from "../../../../helpers/axios_helpers";
import {Message, MESSAGE_CONSTANT, messageDataImpl} from "../../../../constants/type/Message";
import Avatar from "@mui/material/Avatar";
import {BASEURL_RESOURCES, HTTP_MESSAGE_URL} from "../../../../config/AppUrl";
import React from "react";
import purify from "dompurify";
import {classeRoomDataImpl} from "../../../../constants/type/ClasseRoom";
import {Parent} from "../../../../constants/type/Parent";
import moment from "moment";
import webSocketBusinessLogic from "./WebSocketBusinesLogic";
import {SpaceInitiationModel, spaceInitiationModelImpl} from "../../../../constants/type/SpaceInitiationModel";
import spaceListBusinessLogic from "./SpaceListBusinessLogic";
import {FormattedMessage} from "react-intl";
import messages from "../../general/messages";
import Link from "@mui/material/Link";
import {DiscussionEtat} from "../../../../constants/type/DiscussionEtat";
import {isPositiveValue} from "../../../../helpers/helpers";
import {getErrorMessage} from "../../../../helpers/errorManager";


const spaceRoomBusinessLogic: any = {
    scrollToBase: (logicRef:any, temps:number) => {
        _.delay(function() {
            const ctn:any = document.getElementById('roomContainer');
            if(_.isNull(ctn) === false && _.isUndefined(ctn) === false){
                // @ts-ignore
                //ctn.scrollTop = ctn.scrollHeight;
                ctn.scrollTo(0, ctn.scrollHeight);
            }
        }, temps, 'later');
    },
    resetInput: (logicRef:any) => {
        spaceRoomBusinessLogic.updateTextMessage(logicRef, ``);
        logicRef.setNewTicketSubject(``);
        spaceRoomBusinessLogic.scrollToBase(logicRef, 250);
        //logicRef.messageFieldRef.current.value = '';

    },
    envoieFichier: (logicRef:any, file:any) => {
        let formData:any = new FormData();
        formData.append("fichier", file);

        const message = MESSAGE_CONSTANT.convertToBackEnd(spaceRoomBusinessLogic.initMessageObject(logicRef, ``)) ;

        formData.append('model', new Blob([JSON.stringify(message)], {
            type: "application/json"
        }));
        logicRef.setUploadingMsg(true);
        return new Promise<boolean>(resolve =>
            uploadFileRequest('POST',  `${HTTP_MESSAGE_URL}/ws/chat/fichier`, formData).then((response) => {
                resolve(true);
                logicRef.setUploadingMsg(false);
            }).catch((error) => {
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                logicRef.setUploadingMsg(false);
                resolve(false);
            })
        );
    },
    sendMessageByEnter: (logicRef:any, event:any) => {
        if (event.key !== 'Enter'){
            return;
        }
        if (_.isEmpty(event.target.value)){
            return;
        }
        spaceRoomBusinessLogic.sendMessage(logicRef, event.target.value);
    },
    initMessageObject: (logicRef:any, msgtext:any):Message => {
        const userChat      = logicRef.userChatRef.current;
        const message       = _.cloneDeep(messageDataImpl);
        const latade        =  moment();
        message.filDiscussionId     = logicRef.space.id;
        message.message             = msgtext;
        message.userId              = userChat.userId;
        message.userUuid            = userChat.uuid;
        message.roleUser            = userChat.role;
        message.userNom             = userChat.nom;
        message.userPhoto           = userChat.photo;
        message.theDate             = latade.valueOf();
        message.theDateForm         = latade.format('LT');
        return message ;
    },
    sendMessage: (logicRef:any, val:any) => {
        const message = spaceRoomBusinessLogic.initMessageObject(logicRef, val);
        webSocketBusinessLogic.sendMessage(logicRef, message);
        //spaceRoomBusinessLogic.updateSpaceNewMessage(logicRef, message);
        spaceRoomBusinessLogic.resetInput(logicRef);
    },
    sendMessageForNewSpaces: (logicRef:any, val:any) => {
        if(_.isEmpty(logicRef.newChipSpaces)){
            logicRef.snackbarShowMessage(<FormattedMessage id={messages.please_select_parent} />, 'warning');
            return ;
        }
        if(_.size(logicRef.newTicketSubject) < 2){
            logicRef.snackbarShowMessage(<FormattedMessage id={messages.please_write_subject_message} />, 'warning');
            return ;
        }
        const userChat              = logicRef.userChatRef.current;
        const message       = _.cloneDeep(messageDataImpl);
        const latade        =  moment();
        message.filDiscussionId     = logicRef.space.id;
        message.message             = val;
        message.userId              = userChat.userId;
        message.userUuid            = userChat.uuid;
        message.roleUser            = userChat.role;
        message.userNom             = userChat.nom;
        message.userPhoto           = userChat.photo;
        message.theDate             = latade.valueOf();
        message.theDateForm         = latade.format('LT');

        const model             = _.cloneDeep(spaceInitiationModelImpl);
        model.userChatModel     = logicRef.userChatRef.current;
        model.message           = val;

        logicRef.newChipSpaces.forEach((item:FilDiscussion) => {
            const space         = _.cloneDeep(item);
            space.theDate       = moment().valueOf() ;
            space.objet         = logicRef.newTicketSubject ;
            model.filDiscussions.push(FILDISCUSSION_CONSTANT.convertToBackEnd(logicRef, space));
        });
        spaceRoomBusinessLogic.postNewSpaces(logicRef, model);
    },
    deleteSpace: (logicRef:any, space:FilDiscussion) => {
        return new Promise<boolean>(resolve =>
            request('DELETE',  `${HTTP_MESSAGE_URL}/ws/chat/space/${space.id}`, logicRef.userChatRef.current).then((response) => {
                const spacetodel = [space];
                spaceListBusinessLogic.removeConversation(logicRef, spacetodel);
                logicRef.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                resolve(true);
            }).catch((error) => {
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    closeSpace: (logicRef:any, space:FilDiscussion) => {
        return new Promise<boolean>(resolve =>
            request('PUT',  `${HTTP_MESSAGE_URL}/ws/chat/close/space/${space.id}`, logicRef.userChatRef.current).then((response) => {
                const spaces = _.cloneDeep(logicRef.spaceList);
                for(const rowSpace of spaces){
                    if(rowSpace.id !== space.id){
                        continue;
                    }
                    rowSpace.filEtat = DiscussionEtat.CLOS;
                    break;
                }
                const orderSpaces = _.reverse(_.sortBy(spaces, ['theDate']));
                //logicRef.setSpaceList(orderSpaces);
                logicRef.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                spaceListBusinessLogic.updateConversationList(logicRef, logicRef.space, orderSpaces);
                resolve(true);
            }).catch((error) => {
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    postNewSpaces: (logicRef:any, model:SpaceInitiationModel) => {
        logicRef.setProcessingFolderStateNormBtn(true);
        return new Promise<boolean>(resolve =>
            request('POST',  `${HTTP_MESSAGE_URL}/ws/chat`, model).then((response) => {
                logicRef.setNewChipSpaces([]);
                logicRef.setOpenNewTicketDialog(false);
                logicRef.snackbarShowMessage(<FormattedMessage id={messages.sb_success} />);
                spaceListBusinessLogic.addNewConversation(logicRef, response.data);
                logicRef.setProcessingFolderStateNormBtn(false);
                resolve(true);
            }).catch((error) => {
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                logicRef.setProcessingFolderStateNormBtn(false);
                resolve(false);
            })
        );
    },
    updateTextMessage: (logicRef:any, val:any) => {
        logicRef.setTextMessage(purify.sanitize(val ?? ''));
    },
    processingNewMessage: (logicRef:any, data:Message) => {
        spaceListBusinessLogic.updateSpaceInfoFromNewMessage(logicRef, data);
        if(data.filDiscussionId === logicRef.space.id){
            const msgs = _.cloneDeep(logicRef.messages);
            msgs.push(data);
            spaceRoomBusinessLogic.updateMessageList(logicRef, msgs);
        }
    },
    updateMessageList: (logicRef:any, datas:Message[]) => {
        logicRef.setMessages(datas);
        logicRef.setSendingMsg(false);
    },
    listerLesMessages: (logicRef:any):any => {
        spaceRoomBusinessLogic.scrollToBase(logicRef, 350);
        return logicRef.messages.map((data:Message) => spaceRoomBusinessLogic.messageToAppend(logicRef, data)) ;
    },
    messageToAppend: (logicRef:any, data:Message) => {
        let renderHTML:any = <span dangerouslySetInnerHTML={{ __html: purify.sanitize(data.message ?? '') }} />;

        // @ts-ignore
        if(_.isEmpty(data.fichier) === false && data.fichier?.length > 10){
            const urlfichier = `${BASEURL_RESOURCES}/${data.fichier}` ;
            renderHTML = <span><Link href={urlfichier} rel="noopener" target="_blank" underline="hover">{data.nomFichier}</Link></span>;
            if(data.typeFichier === 'image'){
                const image = <>
                    <img
                        srcSet={`${urlfichier}?w=150&fit=crop&auto=format&dpr=2 2x`}
                        src={`${urlfichier}?w=150&fit=crop&auto=format`}
                        alt={data.nomFichier}
                        loading="lazy"
                        style={{display:'block'}}
                    />
                    <Link href={urlfichier}
                          sx={{display:'inline-block'}}
                          rel="noopener"
                          target="_blank"
                          underline="hover">{data.nomFichier}</Link>
                </>;
                renderHTML =<span>{image}</span>;

            }

        }
        const classeStyle = data.userUuid === logicRef.userUUIDRef.current ? logicRef.chatStyles.to : logicRef.chatStyles.from;
        const fromUserNom = data.userUuid === logicRef.userUUIDRef.current ? `` :  <span className="username">{data.userNom}</span>;

        return <li className={classeStyle} key={data.id}>
            <time dateTime={data.theDateForm}>{data.theDateForm}</time>
            {fromUserNom}
            <Avatar
                alt={data.userNom}
                src={`${BASEURL_RESOURCES}/${data.userPhoto}`}
                className={logicRef.chatStyles.avatar} />
            <div className={logicRef.chatStyles.talk}>
                <p>
                    {renderHTML}
                </p>
            </div>
        </li>;
    },
    loadMessageList:(logicRef:any, fildiscussionId:number|undefined|null) => {
        logicRef.setShowChatRoom(true);
        if(isPositiveValue(fildiscussionId) === false){
            spaceRoomBusinessLogic.updateMessageList(logicRef, []);
            return ;
        }
        logicRef.setLoadingMsg(true);
        return new Promise<boolean>(resolve =>
            request("PUT",  `${HTTP_MESSAGE_URL}/ws/chat/messages/space/${fildiscussionId}`, logicRef.userChatRef.current).then((response) => {
                const msgOrdered : Message[]   = _.sortBy(response.data ?? [], ['theDate']);
                spaceRoomBusinessLogic.updateMessageList(logicRef, _.map(msgOrdered,  MESSAGE_CONSTANT.convertToFrontEnd)) ;
                logicRef.setLoadingMsg(false);
                resolve(true);
            }).catch((error) => {
                logicRef.setLoadingMsg(false);
                logicRef.snackbarShowMessage(getErrorMessage(error)?.message, 'error');
                resolve(false);
            })
        );
    },
    handleComposeNewMessage: (logicRef:any) => {
        //webSocketBusinessLogic.clearWebSocketMessageSubscribe(logicRef);
        spaceRoomBusinessLogic.loadMessageList(logicRef,0);
        spaceListBusinessLogic.updateConversationList(logicRef, _.cloneDeep(filDiscussionDataImpl), logicRef.spaceList);
        logicRef.setOpenNewTicketDialog(true);
    },
    handleChildrenSelected: (logicRef:any, event: any) => {
        // @ts-ignore
        const value:number[] = event.target.value;
        spaceRoomBusinessLogic.updateChildrenSelected(logicRef, value);
    },
    updateHeaderAnchor: (logicRef:any, evt:any) => {
        logicRef.setAnchorElHeader(evt?.currentTarget ?? null);
    },
    handleClasseRoomChange: (logicRef:any, event: Object) => {
        // @ts-ignore
        const id = parseInt(event.target.value);
        let classeroom =  _.find(logicRef.classeRoomRef.current, {id:id}) ;
        if(_.isNull(classeroom) || _.isUndefined(classeroom)){
            classeroom = _.cloneDeep(classeRoomDataImpl);
        }
        logicRef.setClasseRoomSelected(_.cloneDeep(classeroom));
    },
    handleNewTicketSubjectChange: (logicRef:any, event: any) => {
        logicRef.setNewTicketSubject(event.target.value);
    },
    updateChildrenSelected: (logicRef:any, ids:number[]) => {
        const newspaceList:FilDiscussion[] = [];
        const userChat = logicRef.userChatRef.current;

        for(const enfantParentId of ids)
        {
            for(const child of logicRef.childrenClasseRef.current)
            {
                let parent: Parent|null = null ;
                // @ts-ignore
                if( child.enfantParents[0].id === enfantParentId){
                    // @ts-ignore
                    parent = child.enfantParents[0].parent ;
                }
                // @ts-ignore
                else if( child.enfantParents[1].id === enfantParentId){
                    // @ts-ignore
                    parent = child.enfantParents[1]?.parent ;
                }

                if(_.isObject(parent)){
                    const space = _.cloneDeep(filDiscussionDataImpl);
                    // @ts-ignore
                    space.enfantParentIdForm  = enfantParentId ;
                    // @ts-ignore
                    space.enfantId      = child.id ;
                    // @ts-ignore
                    space.parentUuid    = parent.uuid ;
                    // @ts-ignore
                    space.parentUserId  = parent.userId ;
                    space.parentNom     = `${parent.person.nom} ${parent.person.prenom}` ;
                    space.enfantPhoto   = child.person?.photo ;
                    // @ts-ignore
                    space.enfantNom     = child.childNomPrenomForm ;
                    space.classeId      = logicRef.classeRoomSelected.id ;
                    space.parentSexe    = parent.person.sexe ;


                    space.employeeUuid          = userChat.uuid ;
                    space.employeeUserId        = userChat.userId ;
                    space.employeeNom           = userChat.nom ;
                    space.employeeFonction      = userChat.fonction ;
                    space.employeePhoto         = userChat.photo ;
                    space.employeeRole          = userChat.role ;
                    space.theDate               = moment().valueOf() ;

                    newspaceList.push(space);
                    break;
                }
            }
        }

        logicRef.setNewChipSpaces(newspaceList);
    },
    initElementRef: (logicRef:any) => {
        logicRef.rooMsgContainerRef.current = document.getElementById('roomContainer');
    }

}

export default spaceRoomBusinessLogic;