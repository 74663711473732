import axios from 'axios';

// LOCALHOST CONFIG
//axios.defaults.baseURL = 'http://108.230.241.172:8080/api/v1'; //BRANDON
//axios.defaults.baseURL = 'http://localhost:8080/api/v1';

// PROD CONFIG
axios.defaults.baseURL = 'https://ivorymontessorisystem.com:8443/api/v1';

// LOCALHOST CONFIG AWS
//axios.defaults.baseURL = 'http://192.168.1.26:8080/api/v1';

axios.defaults.headers.post["Content-Type"] = "application/json";

export const getAuthToken = () => {
    return window.localStorage.getItem("auth_token")
}
export const setAuthToken = (token: string) => {
    window.localStorage.setItem("auth_token", token);
}

export const removeAuthToken = () => {
    window.localStorage.removeItem("auth_token");
}

export const request = (method: any, url: any, data: any) => {
    //let headers = {"Content-Type": "multipart/form-data"};
    let headers = {};
    if (getAuthToken() !== null) {
        headers = {...headers, ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    return axios({
        method: method,
        headers: headers,
        url: url,
        data: data,
    });
}

export const uploadFileRequest = (method: any, url: any, data: any) => {
    //let headers = {};
    let headers = {"Content-Type": "multipart/form-data"};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    return axios({
        method: method,
        headers: headers,
        url: url,
        data: data,
    })
}

export const uploadFileRequestAsync = async (method: any, url: any, data: any) => {
    //let headers = {};
    let headers = {"Content-Type": "multipart/form-data"};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    return await axios({
        method: method,
        headers: headers,
        url: url,
        data: data,
    })
}


export const getRequest = async (url:any) => {
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }
    const config = {
        headers:headers
    }
    const response = await axios.get(url, config);
    return response.data;
}

export const postRequest = async (url:string, data:object) => {
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    const config = {
        headers:headers
    }
    return await axios.post(url, data, config);
}

export const putRequest = async (url:string, data:object) => {
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    const config = {
        headers:headers
    }
    return await axios.put(url, data, config);
}

export const deleteRequest = async (url:string, data:any) => {
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }
    return await axios.delete(url,  { headers, data});
}

export const uploadFilePostRequest = async (url: any, data: any) => {
    //let headers = {};
    let headers = {"Content-Type": "multipart/form-data"};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    const config = {
        headers:headers
    }
    return await axios.post(url, data, config);
}


export const getData = async (url:any) => {
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = { ...headers,  ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }
    const config = {
        headers:headers
    }

    const response = await axios.get(url, {headers:headers} );
    return response.data;
};


/*export const sortOrFilterRequest = (method: any, url: any, params:any) => {
    //let headers = {"Content-Type": "multipart/form-data"};
    let headers = {};
    if (getAuthToken() !== null && getAuthToken() !== "null") {
        headers = {...headers, ...{"Authorization": `Bearer ${getAuthToken()}`} }
    }

    /!*return axios.get(url, {
        params: params
    });*!/
    return axios({
        method: method,
        headers: headers,
        url: url,
        params: params,
    })
}*/
