import React, {useEffect, useState} from "react";
import PapperBlock from "../../components/ui/papperBlock/PapperBlock";
import {makeStyles} from "@mui/styles";
import {Grid, MenuItem, Tab, Tabs, TextField, Typography} from "@mui/material";
import {FormattedMessage} from "react-intl";
import messages from "../messages/general/messages";
import IconButton from "@mui/material/IconButton";
import Box from "@mui/material/Box";
import TabsPanel from "../../components/ui/tabs/TabsPanel";
import TabContent from "../../components/ui/tabs/TabContent";
import EditIcon from '@mui/icons-material/Edit';
import CloseIcon from '@mui/icons-material/Close';
import YearMenuAction from "./YearMenuAction";
import DishDialogForm from "./DishDialogForm";
import {dropZoneFilePreview} from "../../helpers/helpers";
import AddToMenuDiaglogForm from "./AddToMenuDiaglogForm";
import AddNewMenuDialogForm from "./AddNewMenuDialogForm";
import {useForm} from "react-hook-form";
import {CONSTANT, IMGS} from "../../constants";
import {request, uploadFileRequest} from "../../helpers/axios_helpers";
import {withSnackbar} from "../../components/ui/snackbar/SnackbarHOC";
import moment from "moment";
import {BASEURL_RESOURCES} from "../../config/AppUrl";
import PeriodService from "../../service/PeriodService";
import MenuYearService from "../../service/MenuYearService";
import WeekService from "../../service/WeekService";
import WorkDayService from "../../service/WorkDayService";
import {GenrePlat} from "../../constants/type/GenrePlat";
import {menuPlatCantineDataImpl} from "../../constants/type/MenuPlatCantine";
import _ from "lodash";
import {SCHOOL_DAY_MENU_EN, SCHOOL_DAY_MENU_FR} from "../../constants/schoolDay";


const useStyles = makeStyles((theme:any) => {
    return {
        root: {
            padding: theme.spacing(2),
            fontSize: '14px',
            paddingTop: 30,
            '& .css-1ygulig-MuiTypography-root': {
                margin: 0,
                fontSize: '1rem',
                fontWeight:500,
                display: 'block',
                marginTop: 5,
            }
        },
        title: {
            fontWeight:'500 !important'
        },
        list: {
            width: '100%',
            maxWidth: 400,
            marginTop:3,
        },
        save_button: {
            marginTop: 20,
            marginBottom: 10
        },
        btnChangeWeek:{},
        weekInputField: {
            display:'flex',
            margin:'auto',
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input':{
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft:0,
                paddingRight:0
            },
            '& $btnChangeWeek':{
                textTransform:'none',
                color: theme.palette.text.secondary,
                fontWeight:500,
            }
        },
        imgItem:{},
        menuCanteen:{
            display: 'flex',
            flexDirection: 'row',
        },
        boxImg:{
            width:'260px',
            height:'190px',
            overflow:'hidden',
            marginRight:20,
            borderRadius: '10px',
            '& $imgItem':{
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    height:'100%',
                },
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }
        },
        contentCanteen: {
            marginRight:20,
            fontSize:16,
            width:'90%'
        },
        inputField: {
            '& .css-ov5o72-MuiTypography-root': {
                fontWeight: 'normal',
            },
            '& .MuiOutlinedInput-input':{
                fontWeight: 'normal',
                fontSize: '0.97rem',
            },
            '& .css-38f7aq-MuiInputBase-root-MuiOutlinedInput-root': {
                paddingLeft:0,
                paddingRight:0
            },
        },
        inputFieldMarginBottom:{
            marginBottom: 15,
        },
        iconButtonLabel:{},
        dialogAvatar:{
            width:165,
            margin:'auto',
            overflow:'hidden',
            display:'flex',
            marginBottom:20,
            '& $iconButtonLabel':{
                position:"relative",
                top:47,
                left:-20,
            },
            '& $iconButton:hover':{
                backgroundColor:theme.palette.secondary.light,
            },
        },
        iconButton:{
            color:'white',
            width:25,
            height:25,
            padding:5,
            borderRadius:30,
            backgroundColor:theme.palette.secondary.main,
            cursor:'pointer',
        },
        toMenuBoxImg:{
            width:'220px',
            height:'150px',
            overflow:'hidden',
            marginRight:20,
            borderRadius: '10px',
            '& $imgItem':{
                width: '100%',
                [theme.breakpoints.up('sm')]: {
                    height:'100%',
                },
                backgroundSize: 'cover',
                backgroundRepeat: 'no-repeat',
            }
        },
        toMenuContentCanteen: {
            marginRight:20,
            fontSize:15,
            width:'95%',
            //backgroundColor: 'yellow'
        },
    }
});

type dishType = {
    id: number,
    nom:string,
    typePlat: string,
}

type dishFormValues = {
    id: number;
    typePlat: string,
    nom: string,
}

function a11yProps(index: number) {
    return {
        id: `ims-tab-${index}`,
        'aria-controls': `ims-tabpanel-${index}`,
    };
}

function TabContentCanteen (props:any) {
    const { classes, handleEditMenu, valueTab=0, dayMenuDetails, handleDeleteToMenu } = props;

    let contentTab = (<div style={{ textAlign:'center' }}>{<FormattedMessage id={messages.empty_tab} />}</div>);
    if(dayMenuDetails.length > 0){
        contentTab = dayMenuDetails.map((detailsMenu:any, index:any) => (
            <div style={{display:'flex', borderBottom:'1px solid #dedede', paddingBottom:20, marginBottom:20}} key={detailsMenu.nom+index}>
                <Box className={classes.boxImg}>
                    <img
                        className={classes.imgItem}
                        loading="lazy"
                        src={detailsMenu.photo}
                    />
                </Box>
                <div className={classes.contentCanteen}>
                    <Typography>{detailsMenu.nom}</Typography>
                    <div style={{marginTop:10}}>
                        <i><FormattedMessage id={messages.starter_menu_year} /> :</i> <br />
                        {detailsMenu.entree}<br /><br />
                        <i><FormattedMessage id={messages.dish_menu_year} /> :</i> <br />
                        {detailsMenu.plat}<br /><br />
                        <i><FormattedMessage id={messages.dessert_menu_year} /> :</i> <br />
                        {detailsMenu.dessert}
                    </div>
                </div>
                <div>
                    <Box sx={{ display:{ sm:'flex'} }}>
                        <IconButton color={"primary"} onClick={() => handleEditMenu(detailsMenu.menuCanteenJourId)}>
                            <EditIcon />
                        </IconButton>
                        <IconButton color={"primary"} onClick={() => handleDeleteToMenu(detailsMenu.menuCanteenJourId)}>
                            <CloseIcon />
                        </IconButton>
                    </Box>
                </div>
            </div>
        ));
    }

    return (
        <TabContent value={valueTab} index={valueTab}>
            {contentTab}
        </TabContent>
    );

}

function MenuYear(props:any){
    const {theme, locale, snackbarShowMessage} = props
    const classes = useStyles();
    const activeLanguage = locale.split('-')
    const [valueTab, setValueTab] = React.useState(0);
    const [dishType, setDishType] = useState<any>('');

    const [dishName, setDishName] = useState('')
    const [dish, setDish] = useState<any>(null);
    const [starterDish, setStarterDish] = useState<dishType | null>(null);
    const [dessertDish, setDessertDish] = useState<dishType | null>(null);
    const [dataStaterDish, setDataStaterDish] = useState<any>([]);
    const [dataDish, setDataDish] = useState<any>([]);
    const [dataDessertDish, setDataDessertDish] = useState<any>([]);
    const [menuPLatCanteen, setMenuPLatCanteen] = useState<any>([])

    const [file, setFile] = useState(null);
    const [fileDataURL, setFileDataURL] = useState(null);
    const [openDish, setOpenDish] = useState<boolean>(false);
    const [openMenu, setOpenMenu] = useState<boolean>(false);
    const [openToMenu, setOpenToMenu] = useState<boolean>(false);
    const [searchMenu, setSearchMenu] = useState('');

    const [dataMenu, setDataMenu] = useState<any>([]);
    const [dataMenuCopy, setDataMenuCopy] = useState<any>([]);

    const [dataMenuList, setDataMenuList] = useState<any>([]);
    const [weekData, setWeekData] = useState<any>([]);
    const [week, setWeek] = useState<any | ''>('');
    const [generatedWeek, setGeneratedWeek] = useState<any>([]);
    const [allCanteenWeek, setAllCanteenWeek] = useState<any>([]);
    const [statusMenuApp, setStatusMenuApp] = useState(false);
    const [stateMenu, setStateMenu] = useState(true);
    const [checked, setChecked] = React.useState<number[]>([]);
    const [workDaysList, setWorkDaysList] = useState<any>([]);
    const [dataMenuJourList, setDataMenuJourList] = useState<any>([]);
    const [loadingButtonGenerate, setLoadingButtonGenerate] = useState(false);

    const placeholderStarterDish = activeLanguage[0] === 'en' ? 'Starter dish' : 'Entrée'
    const placeholderDish = activeLanguage[0] === 'en' ? 'Dish' : 'Plat';
    const placeholderDessert = activeLanguage[0] === 'en' ? 'Dessert' : 'Dessert';

    const [dayMenuDetails, setDayMenuDetails] = useState<any>([]);
    const [menuCanteenEdit, setMenuCanteenEdit] = useState<any>(null);

    const periodService = PeriodService();
    const menuYearService = MenuYearService();
    const weekService = WeekService();
    const workDayService = WorkDayService();

    const form = useForm<dishFormValues>({
        defaultValues: {
            id: 0,
            typePlat: '',
            nom: '',
        }
    });
    const {control, register, handleSubmit, formState, reset, setValue} = form;
    const {errors} = formState;

    const handleWeekChange = (event:any) => {
        const weekSelected = event.target.value;
        setWeek(weekSelected);
        const selectedDay = workDaysList[valueTab];
        getMenuDayList(dataMenuJourList, selectedDay, weekSelected, dataMenuList, menuPLatCanteen);
    }

    const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
        setValueTab(newValue);
        const selectedDay = workDaysList[newValue];
        getMenuDayList(dataMenuJourList, selectedDay, week, dataMenuList, menuPLatCanteen);
    }

    // DISH TYPE SETTINGS
    const handleNewDish = () => {
        setDish('');
        setValue('id', 0);
        setValue('typePlat', '');
        setValue('nom', '');

        setOpenDish(true);
    }

    const handleDishTypeChange = (event:any) => {
        setDishType(event.target.value)
    }

    // MENU SETTINGS
    const handleDishNameChange = (event:any) => {
        setDishName(event.target.value);
    }

    const handleStarterDishChange = (starterDish: any) => {
        if(starterDish !== '') {
            setStarterDish(starterDish);
        }
    }

    const handleDishChange = (dishSelected: any) => {
        if(dishSelected !== '') {
            setDish(dishSelected)
        }
    }

    const handleDessertDishChange = (dessert: any) => {
        if(dessert !== '') {
            setDessertDish(dessert)
        }
    }

    const handleAddNewMenu = () => {
        setStateMenu(true);
        setDishName('');
        setFileDataURL(null);
        setFile(null);
        setStarterDish(null);
        setDish(null);
        setDessertDish(null);
        setMenuCanteenEdit(null);

        setOpenMenu(true);
    }

        const handleEditMenu = (menuId:any) => {
        setStateMenu(false);
        setStarterDish(null);
        setDish(null);
        setDessertDish(null);
        setFileDataURL(null);
        setFile(null);
        setDishName('');
        setMenuCanteenEdit(null);

        const menuJourFind = dataMenuJourList.find((menu:any) => menu?.id === menuId);
        let menuCantineJours:any = [];
        if(menuJourFind !== undefined){
            menuCantineJours = menuJourFind?.menuCantineJours;
            if(menuCantineJours.length > 0) {
                const menuFind = dataMenuList.find((menu:any) => menu?.id === menuCantineJours[0]?.menuCantineId);
                const photo:any = menuFind?.photo != '' ? BASEURL_RESOURCES+`/`+menuFind?.photo : IMGS.photoMenu
                setFileDataURL(photo);
                setDishName(menuFind?.nom);
                setMenuCanteenEdit(menuFind);

                const platCantineIdList:any = [];
                if(menuFind?.menuPlatCantines?.length > 0){
                    for(let i=0; i< menuFind?.menuPlatCantines?.length; i++){
                        platCantineIdList.push(menuFind?.menuPlatCantines[i]?.platCantineId);
                    }
                }

                if(platCantineIdList.length > 0 && menuPLatCanteen?.length > 0) {
                    for(let i=0; i < platCantineIdList.length; i++) {
                        const platFind = menuPLatCanteen.find((plat:any) => plat?.id === platCantineIdList[i]);
                        if(platFind !== undefined){
                            if(platFind?.typePlat === 'ENTREE'){
                                setStarterDish(platFind);
                            }
                            else if(platFind?.typePlat === 'PLAT'){
                                setDish(platFind);
                            }
                            else {
                                setDessertDish(platFind);
                            }
                        }
                    }
                }

                setOpenMenu(true);
            }
        }
    }

    const handleToggle = (value: any) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }
        setChecked(newChecked);
    };

    const sendMenuData = (method: any, url: any, dataToSend: any) => {
        uploadFileRequest(method, url, dataToSend).then((response) => {
            if(stateMenu){
                setDataMenuList([...dataMenuList, response?.data]);
                setStateMenu(true);
                setDishName('');
                setFileDataURL(null);
                setFile(null);
                setOpenMenu(false);
            }
            else {
                //console.log(dataMenuList);
                const dataMenuListEdit = dataMenuList.map((menuList:any) => {
                    if(menuList?.id === response?.data?.id){
                        return response?.data;
                    }
                    else {
                        return menuList;
                    }
                })
                setDataMenuList([...dataMenuListEdit]);

                const selectedDay = workDaysList[valueTab];
                getMenuDayList(dataMenuJourList, selectedDay, week, dataMenuListEdit, menuPLatCanteen);

                setStateMenu(true);
                setDishName('');
                setFileDataURL(null);
                setFile(null);
                setOpenMenu(false);
            }

            snackbarShowMessage(<FormattedMessage id={messages.sb_add_success} />);
        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const onSubmitAddNewMenu = () => {
        if (starterDish !== null && dish !== null && dessertDish !== null && dishName !== null ) {

            let formData: any = new FormData();
            formData.append("photo", file);

            const dataToSend = {
                //id: 0,
                nom: dishName,
                couleur: 'rgba(150, 145, 120, 0.7)',
                photo: '',
                common: CONSTANT.common,
                menuPlatCantines: [
                    {
                        //id:0,
                        platCantineId: starterDish?.id,
                        common: CONSTANT.common,
                    },
                    {
                        //id:0,
                        platCantineId: dish?.id,
                        common: CONSTANT.common,
                    },
                    {
                        //id:0,
                        platCantineId: dessertDish?.id,
                        common: CONSTANT.common,
                    },
                ]
            }

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));
            sendMenuData('POST', "/extra/menucantine", formData);
        }
    }

    const onSubmitEditNewMenu = () => {
        if (starterDish !== null && dish !== null && dessertDish !== null && dishName !== null ) {
            if(menuCanteenEdit !== null){

                let formData: any = new FormData();
                formData.append("photo", file);
                const menujourMap = new Map<string, any>();
                menuCanteenEdit.menuPlatCantines.forEach((plc:any) => {
                    const plat = _.find(menuPLatCanteen, {id:plc.platCantineId});
                    if(_.isObject(plat)){
                        // @ts-ignore
                        menujourMap.set(plat.typePlat, plc);
                    }
                });
                const entree = menujourMap.get(GenrePlat.ENTREE) ?? _.cloneDeep(menuPlatCantineDataImpl);
                const principal = menujourMap.get(GenrePlat.PLAT) ?? _.cloneDeep(menuPlatCantineDataImpl);
                const dessert = menujourMap.get(GenrePlat.DESSERT) ?? _.cloneDeep(menuPlatCantineDataImpl);
                const dataToSend = {
                    id: menuCanteenEdit.id,
                    nom: dishName,
                    couleur: menuCanteenEdit.couleur,
                    photo: menuCanteenEdit.photo,
                    common: menuCanteenEdit.common,
                    menuPlatCantines: [
                        {
                            id: null,
                            platCantineId: starterDish.id,
                            menuCantineId: menuCanteenEdit.id,
                            common: entree.common,
                        },
                        {
                            id: null,
                            platCantineId: dish.id,
                            menuCantineId: menuCanteenEdit.id,
                            common: principal.common,
                        },
                        {
                            id: null,
                            platCantineId: dessertDish.id,
                            menuCantineId: menuCanteenEdit.id,
                            common: dessert.common,
                        },
                    ]
                }
                formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                    type: "application/json"
                }));

                sendMenuData('PUT', `/extra/menucantine/${menuCanteenEdit?.id}`, formData);
            }
        }

    }

    const handleDeleteMenu = (menuCanteenId:any) => {
        request("DELETE", `/extra/menucantine/${menuCanteenId}`, {}).then((response) => {
            const menuCanteenList = dataMenu.filter((menuCanteen:any) => menuCanteen.id !== menuCanteenId);
            setDataMenu(menuCanteenList);

            const menuList = dataMenuList.filter((menu:any) => menu.id !== menuCanteenId);
            setDataMenuList(menuList);

            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);

        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const handleAddToMenu = () => {
        const listMenu:any = [];
        let menuDataTodisplay:any;
        setChecked([]);

        for(let i=0; i < dataMenuList.length; i++) {
            const menuCanteen:any = dataMenuList[i];
            const platCanteen = menuCanteen?.menuPlatCantines !== undefined ? menuCanteen?.menuPlatCantines : [];

            if(platCanteen.length > 0){
                let dessert:any = {};
                let entree:any = {};
                let plat:any = {};
                for(let l=0; l<platCanteen.length; l++){
                    const platCanteenFind = menuPLatCanteen.find((platFind:any) => platFind?.id === platCanteen[l]?.platCantineId);
                    if(platCanteenFind.typePlat === 'ENTREE'){
                        entree = platCanteenFind;
                    }

                    if(platCanteenFind.typePlat === 'PLAT'){
                        plat = platCanteenFind;
                    }

                    if(platCanteenFind.typePlat === 'DESSERT'){
                        dessert = platCanteenFind;
                    }
                }
                /*const entree = dataStaterDish.find((starter:any) => starter?.id === platCanteen[0]?.platCantineId);
                const plat = dataDish.find((dish:any) => dish?.id === platCanteen[1]?.platCantineId);
                const dessert = dataDessertDish.find((dessert:any) => dessert?.id === platCanteen[2]?.platCantineId);*/

                const photoMenu = menuCanteen?.photo != '' ? BASEURL_RESOURCES+`/`+menuCanteen?.photo : IMGS.photoMenu
                menuDataTodisplay = {
                    id: menuCanteen?.id,
                    nom: menuCanteen?.nom,
                    starterDish: entree !== undefined ? entree?.nom : '',
                    dish: plat !== undefined ? plat?.nom : '',
                    dessert: dessert !== undefined ? dessert?.nom : '',
                    photo: photoMenu,
                }
                listMenu.push(menuDataTodisplay);
            }
        }

        setDataMenu([...listMenu]);
        setDataMenuCopy([...listMenu]);

        setOpenToMenu(true);
    }

    const handleDeleteToMenu = (menuCanteenJourId:any) => {
        request("DELETE", `/extra/menujour/${menuCanteenJourId}`, {}).then((response) => {
            const menuJourList = dataMenuJourList.filter((menuJour:any) => menuJour?.id !== menuCanteenJourId);
            setDataMenuJourList(menuJourList);

            const detailsMenuJourList = dayMenuDetails.filter((details:any) => details?.menuCanteenJourId !== menuCanteenJourId);
            setDayMenuDetails(detailsMenuJourList);
            snackbarShowMessage(<FormattedMessage id={messages.sb_delete_success} />);

        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const handleFileChange = (event:any) => {
        const file = event.target.files[0];
        setFile(file);
    }

    const handleSearchMenu = (event:any) => {
        const searchTerm = event.target.value !== undefined ? event.target.value : null;
        setSearchMenu(searchTerm);

        if(searchTerm.length > 0){
            //console.log(searchTerm)
            let listMenu:any = [];
            dataMenu.map((data:any) => {
                const text = data?.nom.toLowerCase();
                const term = searchTerm.toLowerCase();
                if(text.includes(term)){
                    listMenu.push(data);
                }
            });
            setDataMenu(listMenu);
        }
        else {
            setDataMenu(dataMenuCopy)
        }
    }

    const sendDishData = (data: any, method: any, url: any, dataToSend: any) => {
        uploadFileRequest(method, url, dataToSend).then((response) => {
            reset();
            setOpenDish(false);

            // GET PLAT CANTEEN STATER DISH - DISH - DESSERT
            const dishRequestList:any = [...menuPLatCanteen, response?.data];;
            //STARTER DISH
            const starterDishList = dishRequestList.filter((dish:any) => {
                if(dish?.typePlat === 'ENTREE'){
                    return dish;
                }
            });
            setDataStaterDish([...starterDishList]);

            //DISH
            const dishList = dishRequestList.filter((dish:any) => {
                if(dish?.typePlat === 'PLAT'){
                    return dish;
                }
            });
            setDataDish([...dishList]);

            //DESSERT
            const dessertList = dishRequestList.filter((dish:any) => {
                if(dish?.typePlat === 'DESSERT'){
                    return dish;
                }
            });
            setDataDessertDish([...dessertList]);

            setMenuPLatCanteen(dishRequestList);

            snackbarShowMessage(<FormattedMessage id={messages.sb_add_success} />);
        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const onSubmitDish = (data: dishFormValues) => {
        let formData:any = new FormData();
        formData.append("photo", null);

        if(dishType !== ''){
            const dataToSend = {
                typePlat: dishType?.key,
                nom: data?.nom,
                details:'',
                photo:'',
                common: CONSTANT.common
            }

            formData.append('model', new Blob([JSON.stringify(dataToSend)], {
                type: "application/json"
            }));
            //console.log(JSON.stringify(dataToSend));
            sendDishData(data, 'POST', "/extra/platcantine", formData);
        }
    }

    const sendAddToMenuData = (method: any, url: any, dataToSend: any) => {
        request(method, url, dataToSend).then((response) => {
            const menuJourListRequest:any = [...dataMenuJourList, response?.data];
            setDataMenuJourList(menuJourListRequest);
            getMenuDayList(menuJourListRequest, workDaysList[valueTab], week, dataMenuList, menuPLatCanteen);

            setOpenToMenu(false);
            snackbarShowMessage(<FormattedMessage id={messages.sb_add_success} />);
        }).catch((error) => {
            // Error message
            console.log(error);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        });
    }

    const onSubmitAddToMenu = () => {
        const dateCanteen:any = moment(new Date());
        const day:string = workDaysList[valueTab]?.jour.toUpperCase();

        if(checked.length > 0) {
            const menuCanteenDay = checked.map((menu:any) => {
                return {
                    //id: 0,
                    //menuJourId: 0,
                    menuCantineId: menu?.id,
                    common: CONSTANT.common,
                }
            });

            const dataToSend = {
                theDate: dateCanteen.valueOf(),
                semaineId: week?.id,
                nom: '',
                couleur: 'rgba(150, 145, 120, 0.7)',
                jour: day,
                common: CONSTANT.common,
                menuCantineJours: menuCanteenDay,
            }

            sendAddToMenuData('POST', "/extra/menujour", dataToSend);
        }
    }

    useEffect(() => {
        dropZoneFilePreview(file, setFileDataURL);
    }, [file]);

    const getMenuDayList = (menuJourListPar:any, selectedDay:any, selectedWeek:any, menuListPar:any, menuPLatCanteenPar:any) => {
        const dayListMenuTab:any = [];
        let dayListMenu:any = {};

        const menuJourList = menuJourListPar !== undefined ? menuJourListPar : [];
        for(let i=0; i<menuJourList.length; i++) {
            const dayMenu = menuJourList[i]

            if(dayMenu?.jour === selectedDay?.jour.toUpperCase() && dayMenu?.semaineId === selectedWeek?.id){
                const menuCantineJours = dayMenu?.menuCantineJours;
                const menuList = menuListPar !== undefined ? menuListPar : [];
                const menuPLatCanteen = menuPLatCanteenPar !== undefined ? menuPLatCanteenPar : [];

                if(menuCantineJours.length > 0) {
                    for(let j=0; j < menuCantineJours.length; j++){
                        const menuCantine = menuList.filter((menu:any) => menu?.id === menuCantineJours[j]?.menuCantineId);
                        if(menuCantine.length > 0) {
                            for(let k=0; k < menuCantine.length; k++) {

                                dayListMenu = {
                                    nom: menuCantine[k]?.nom,
                                    photo: menuCantine[k]?.photo !== '' ? `${BASEURL_RESOURCES}/${menuCantine[k]?.photo}` : IMGS.photoMenu,
                                }

                                const platCantines = menuCantine[k]?.menuPlatCantines !== undefined ? menuCantine[k]?.menuPlatCantines : [];
                                if(platCantines.length > 0){
                                    let entreeFind:any = {}; let platFind:any = {}; let dessertFind:any = {};
                                    let menuPlat:any = {};
                                    for(let l=0; l<platCantines.length; l++){
                                        const platCanteenFind = menuPLatCanteen.find((platFind:any) => platFind?.id === platCantines[l]?.platCantineId);
                                        if(platCanteenFind.typePlat === 'ENTREE'){
                                            menuPlat = {
                                                ...menuPlat,
                                                entree:platCanteenFind?.nom,
                                            }
                                        }

                                        if(platCanteenFind.typePlat === 'PLAT'){
                                            //platFind = platCanteenFind;
                                            menuPlat = {
                                                ...menuPlat,
                                                plat:platCanteenFind?.nom,
                                            }
                                        }

                                        if(platCanteenFind.typePlat === 'DESSERT'){
                                            //dessertFind = platCanteenFind;
                                            menuPlat = {
                                                ...menuPlat,
                                                dessert:platCanteenFind?.nom,
                                            }
                                        }
                                    }
                                    //entreeFind = menuPLatCanteen.find((entree:any) => entree?.id === platCantines[0]?.platCantineId);
                                    //platFind = menuPLatCanteen.find((entree:any) => entree?.id === platCantines[1]?.platCantineId);
                                    //dessertFind = menuPLatCanteen.find((entree:any) => entree?.id === platCantines[2]?.platCantineId);

                                    dayListMenu = {
                                        ...dayListMenu,
                                        /*entree:entreeFind?.nom,
                                        plat:platFind?.nom,
                                        dessert:dessertFind?.nom,*/
                                        ...menuPlat,
                                        itemMenuCanteenJourId: menuCantineJours[j]?.id,
                                        menuCanteenJourId: dayMenu?.id
                                    }
                                    dayListMenuTab.push(dayListMenu);
                                }
                            }
                        }
                    }
                }
            }
        }

        setDayMenuDetails(dayListMenuTab);
    }

    const generateWeekAndMenu = async () => {
        try {
            // REGISTERED MENU CANTEEN ON MORE WEEKS
            let schoolPeriodsList = await periodService.getAllSchoolPeriod();
            schoolPeriodsList = schoolPeriodsList.sort(function (a:any, b:any) {
                return a.dateDebut - b.dateDebut;
            });
            if(schoolPeriodsList.length > 0) {
                setLoadingButtonGenerate(true);
                moment.locale('en');

                const periodStart = schoolPeriodsList[0];
                const periodEnd = schoolPeriodsList[schoolPeriodsList.length -1]

                const weekNumber = weekService.getWeekNumberTwoDate(periodStart.dateDebut, periodEnd.dateFin);
                const startDay = moment(periodStart.dateDebut).format("L");
                const endDay = moment(periodEnd.dateFin).format("L");
                const startDate:any = new Date(startDay);
                const endDate:any = new Date(endDay);
                //console.log(`The number of weeks between ${startDate} and ${endDate} is ${weekNumber}`);
                //console.log(weekData.length);

                const lastWeekDisplay = await weekService.getLastWeekDisplay();
                if(lastWeekDisplay !== null){
                    const numberOfWeekToGenerate = weekNumber - generatedWeek.length;
                    const generateWeek = await weekService.generateWeek(numberOfWeekToGenerate, lastWeekDisplay, schoolPeriodsList, weekData, allCanteenWeek);
                    snackbarShowMessage(<FormattedMessage id={messages.sb_add_success} />);
                    setLoadingButtonGenerate(false);
                }
            }
        }
        catch (error) {
            console.log(error);
            setLoadingButtonGenerate(false);
            snackbarShowMessage(<FormattedMessage id={messages.sb_action_error} />, 'error');
        }

    }

    useEffect( () => {
        ( async () => {
            try {
                // GET PLAT CANTEEN STATER DISH - DISH - DESSERT
                const dishRequestList:any = await menuYearService.getAllDishData();

                //STARTER DISH
                const starterDishList = dishRequestList.filter((dish:any) => {
                    if(dish?.typePlat === 'ENTREE'){
                        return dish;
                    }
                });
                setDataStaterDish([...starterDishList]);

                //DISH
                const dishList = dishRequestList.filter((dish:any) => {
                    if(dish?.typePlat === 'PLAT'){
                        return dish;
                    }
                });
                setDataDish([...dishList]);

                //DESSERT
                const dessertList = dishRequestList.filter((dish:any) => {
                    if(dish?.typePlat === 'DESSERT'){
                        return dish;
                    }
                });
                setDataDessertDish([...dessertList]);

                setMenuPLatCanteen(dishRequestList);

                // GET MENU CANTEEN
                const menuCaneenRequest:any = await menuYearService.getAllMenuCanteen();
                setDataMenuList(menuCaneenRequest);

                // GET WEEK LIST
                let canteenWeek:any = await weekService.getAllWeekData();
                setAllCanteenWeek(canteenWeek);
                // FILTER FOR CANTEEN WEEK THAT MUST BE DISPLAY
                canteenWeek = canteenWeek.filter((canteen:any) => canteen?.statut === true);
                canteenWeek = canteenWeek.sort(function (a:any, b:any) {
                    return a.dateDebut - b.dateDebut;
                });
                const dataList = canteenWeek.map((week:any) => {
                    moment.locale(activeLanguage[0]);
                    let weekData:any = {};
                    weekData.id = week?.id;
                    weekData.periodId = week?.periodeId;
                    weekData.nom = week?.nom;
                    weekData.startPeriod = moment(week?.dateDebut).format("L");
                    weekData.endPeriod = moment(week?.dateFin).format("L");
                    weekData = {...weekData, ...{common: week.common }}
                    return weekData;
                });

                setWeekData([...dataList]);
                setGeneratedWeek(canteenWeek);
                //SELECT WEEK;
                moment.locale('en');
                let todayThisWeek = moment();
                todayThisWeek.set({hour:0,minute:0,second:0,millisecond:0});
                const todayThisWeekSelect = todayThisWeek.format('x');

                const weekCanteenFind = canteenWeek.filter((week:any) => {
                    if(todayThisWeekSelect >= week?.dateDebut && todayThisWeekSelect <= week?.dateFin){
                        return week;
                    }
                })

                let weekSelected = dataList[0];
                setWeek(dataList[0]);
                if(weekCanteenFind.length > 0) {
                    const findWeek = dataList.find((week:any) => week?.id === weekCanteenFind[0]?.id)
                    setWeek(findWeek);
                    weekSelected = findWeek;
                }

                // GET WORK DAYS LIST
                moment.locale('fr');
                const workDaysListRequest:any = await workDayService.getAllWorkDay();
                const workdaysList = workDaysListRequest.filter((workDay:any) => workDay?.ouvrable);
                setWorkDaysList(workdaysList);

                const theday =  moment(new Date()).format('dddd');
                const today = theday.toUpperCase();
                let selectedDay = workdaysList.find((workday:any) => workday?.jour.toUpperCase() === today);
                if(selectedDay === undefined) {
                    selectedDay = workdaysList[0];
                }

                // GET MENU DAY LIST
                const menuJourListRequest:any = await menuYearService.getAllMenuJour();
                const todayFind = workdaysList.find((workDay:any) => workDay?.jour === selectedDay?.jour);
                const workDayIndex = workdaysList.indexOf(todayFind);

                getMenuDayList(menuJourListRequest, selectedDay, weekSelected, menuCaneenRequest, dishRequestList);
                setDataMenuJourList(menuJourListRequest);
                setValueTab(workDayIndex !== -1 ? workDayIndex : 0);

                //ETAT MENU APP
                const statusMenuYear = await menuYearService.getStatusMenuYear();
                if(statusMenuYear !== undefined && statusMenuYear !== null){
                    setStatusMenuApp(statusMenuYear?.statut);
                }
            }
            catch (error) {
                console.log(error);
            }

        })()
    }, []);


    return (
        <PapperBlock whiteBg noMargin displayNoTitle={true}>
            <div className={classes.root}>
                <Grid container spacing={2}>
                    <Grid item md={12} xs={12} sx={{ display:{xs:'display', sm:'none'} }}>
                        <YearMenuAction
                            handleAddToMenu={() => handleAddToMenu()}
                            handleNewDish={() => handleNewDish()}
                            handleAddNewMenu={() => handleAddNewMenu()}
                            generateWeekAndMenu={() => generateWeekAndMenu()}
                            loadingButtonGenerate={loadingButtonGenerate}
                            statusMenuApp={statusMenuApp}
                            setStatusMenuApp={setStatusMenuApp}
                        />
                    </Grid>
                    <Grid item md={5} xs={12}>
                        <Box className={classes.weekInputField} sx={{ width:{ xs:'100% 1important', sm:'85%'}, }}>
                            {/* <Button
                                sx={{ mr:3, pr:2}}
                                className={classes.btnChangeWeek}
                            >
                                <ArrowLeft />
                                <FormattedMessage id={messages.back_action_stepper} />
                            </Button>*/}
                            <TextField
                                select
                                value={week}
                                sx={{ width: { xs: '100% !important'}, fontWeight:400}}
                                color={"primary"}
                                onChange={(a:any) => handleWeekChange(a)}
                            >
                                {weekData.map((option:any) => {
                                    return (
                                        <MenuItem key={option.id} value={option} sx={{fontWeight:400}}>
                                            {option.nom}
                                        </MenuItem>
                                    )
                                })}
                            </TextField>
                            {/*<Button sx={{ml:3, pl:2}} className={classes.btnChangeWeek}>
                                <FormattedMessage id={messages.next_action_stepper} />
                                <ArrowRight />
                            </Button>*/}
                        </Box>
                    </Grid>
                    <Grid item md={7} xs={12} sx={{ display:{ xs:'none', sm:'block'} }}>
                        <YearMenuAction
                            handleAddToMenu={() => handleAddToMenu()}
                            handleNewDish={() => handleNewDish()}
                            handleAddNewMenu={() => handleAddNewMenu()}
                            generateWeekAndMenu={() => generateWeekAndMenu()}
                            loadingButtonGenerate={loadingButtonGenerate}
                            statusMenuApp={statusMenuApp}
                            setStatusMenuApp={setStatusMenuApp}
                        />
                    </Grid>
                    <Grid item md={9} xs={12}>
                        <TabsPanel>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs value={valueTab}
                                      onChange={handleTabChange}
                                      textColor="primary"
                                      TabIndicatorProps={{
                                          style: {
                                              backgroundColor: theme.palette.primary.main
                                          }
                                      }}
                                    //key={day.id}
                                >
                                    {
                                        workDaysList.map((day:any, index:any) => {
                                            const dayJour:any = day?.jour;
                                            let dayIndex = null;
                                            let dayFunded = '';

                                            for (let i=0; i<SCHOOL_DAY_MENU_FR.length; i++){
                                                if(SCHOOL_DAY_MENU_FR[i] === dayJour?.toUpperCase()){
                                                    dayIndex = i;
                                                    break;
                                                }
                                            }

                                            if(dayIndex !== null){
                                                dayFunded = activeLanguage[0] === 'en' ? SCHOOL_DAY_MENU_EN[dayIndex] : SCHOOL_DAY_MENU_FR[dayIndex];
                                            }
                                            return (<Tab color={"primary"} label={dayFunded} {...a11yProps(index)} key={index} />);
                                        })
                                    }
                                </Tabs>
                            </Box>

                            <TabContentCanteen
                                classes={classes}
                                workDayList={workDaysList}
                                dataMenuJourList={dataMenuJourList}
                                week={week}
                                dayMenuDetails={dayMenuDetails}
                                valueTab={valueTab}
                                handleEditMenu={handleEditMenu}
                                handleDeleteToMenu={handleDeleteToMenu}
                            />

                        </TabsPanel>
                    </Grid>
                </Grid>
            </div>


            {/*New Dish Dialog Form*/}
            <DishDialogForm
                title={ <FormattedMessage id={messages.dialog_title_dish_config} /> }
                theme={theme}
                locale={locale}
                open={openDish}
                setOpen={setOpenDish}
                dish={dishType}
                handleDishTypeChange={handleDishTypeChange}
                //data={dataDishType}
                //onSubmit={onSubmitDish}
                onSubmit={handleSubmit(onSubmitDish)}
                register={register}
            />

            {/*Add a New Menu Dialog Form*/}
            <AddNewMenuDialogForm
                title={ stateMenu ? <FormattedMessage id={messages.add_new_menu_year} />: <FormattedMessage id={messages.edit_menu_year} /> }
                theme={theme}
                locale={locale}
                classes={classes}
                open={openMenu}
                setOpen={setOpenMenu}
                fileDataURL={fileDataURL}
                handleFileChange={handleFileChange}
                dataStaterDish={dataStaterDish}
                handleStarterDishChange={handleStarterDishChange}
                placeholderStarterDish={placeholderStarterDish}
                dataDish={dataDish}
                handleDishChange={handleDishChange}
                placeholderDish={placeholderDish}
                dataDessertDish={dataDessertDish}
                handleDessertDishChange={handleDessertDishChange}
                placeholderDessert={placeholderDessert}
                //onSubmit={onSubmitAddNewMenu}
                onSubmit={stateMenu ? onSubmitAddNewMenu : onSubmitEditNewMenu}
                handleDishNameChange={handleDishNameChange}
                dishName={dishName}
                starterDish={starterDish}
                dish={dish}
                dessertDish={dessertDish}
                //onSubmit={stateDish ? handleSubmit(onSubmit) : handleSubmit(onSubmitEditForm)}
            />

            {/*Add to Menu Dialog Form*/}
            <AddToMenuDiaglogForm
                theme={theme}
                locale={locale}
                open={openToMenu}
                setOpen={setOpenToMenu}
                width={true}
                classes={classes}
                data={dataMenu}
                searchTerm={searchMenu}
                handleSearch={handleSearchMenu}
                onSubmit={onSubmitAddToMenu}
                handleToggle={handleToggle}
                handleDeleteMenu={handleDeleteMenu}
                checked={checked}
                dataMenuJourList={dataMenuJourList}
                dataList={weekData}
            />
        </PapperBlock>
    )
}

export default withSnackbar(MenuYear);
