export const IMS_WEBSITE = 'https://ivorymontessorisystem.com/';


// BRANDON
/*export const BASEURL = 'http://localhost:3000';
export const BASEURL_IMG = 'http://108.230.241.172:81/resources';
export const BASEURL_RESOURCES = 'http://108.230.241.172:81/resources';
export const SOCKET_URL = 'ws://108.230.241.172:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'http://108.230.241.172:8081/api/v1';*/

//export const BASEURL_IMG = 'https://ivorymontessorisystem.com/resources';
//export const BASEURL_RESOURCES = 'https://ivorymontessorisystem.com/resources';
//export const SOCKET_URL = 'wss://ivorymontessorisystem.com:8081/api/v1/ws';
//export const HTTP_MESSAGE_URL = 'https://ivorymontessorisystem.com:8081/api/v1';

// PROD CONFIG33
export const BASEURL = 'https://erp.ivorymontessorisystem.com';
export const BASEURL_IMG = 'https://ivorymontessorisystem.com/resources';
export const BASEURL_RESOURCES = 'https://ivorymontessorisystem.com/resources';
export const SOCKET_URL = 'wss://ivorymontessorisystem.com:8081/api/v1/ws';
export const HTTP_MESSAGE_URL = 'https://ivorymontessorisystem.com:8081/api/v1';
